import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from 'gatsby'
import MarkdownIt from "markdown-it";
import MarkdownItSup from "markdown-it-sup";
import SEO from "../components/seo";

function createMarkup(text) {
    const md = new MarkdownIt()
        .use(MarkdownItSup);
    return {__html: md.render(text)};
}

export default function Category({ data }) {
    const piece = data.wagtail.piece;

    const getPrice = () => {
        if(piece.commission) {
            return (<span className="commission">Commission</span>);
        } if(piece.sold) {
            return (<span className="sold">Sold</span>);
        } else {
            return `$${parseInt(piece.price)}`;
        }
    }

    return (
        <Layout>
            <SEO title={piece.title}
                 image={piece?.image?.thumbnail?.localFile?.childImageSharp?.fluid.src} />
            <div className="piece row">
                <div className="piece__image col col-xl-9 col-lg-8 col-12">
                    <img src={piece?.image?.full?.localFile.childImageSharp.fixed.src} alt={piece.title} />
                </div>
                <div className="col col-xl-3 col-lg-4 col-12 piece__details">
                    <div className="vr"></div>
                    <h1>{piece.title}</h1>
                    <hr/>
                    <div className="row">
                        <div className="col col-6">
                            <dl>
                                <dt>Dimensions</dt>
                                <dd>{piece.dimensions}</dd>
                                <dt>Price</dt>
                                <dd>{getPrice()}</dd>

                            </dl>
                        </div>
                        <div className="col col-6">
                            <dl>
                                <dt>Categories</dt>
                                <dd>
                                    {piece.categories.map((category) => {
                                        return <Link to={`/category/${category.slug}`} key={category.slug} className="category-link">
                                            {category.name}
                                        </Link>
                                    })}
                                </dd>
                                {(() => {
                                    if (piece.mediums?.length) {
                                        return <div>
                                            <dt>Medium</dt>
                                            <dd>
                                                {piece.mediums.map((medium) => {
                                                    return <Link to={`/medium/${medium.slug}`} key={medium.slug}
                                                                 className="category-link">
                                                        {medium.name}
                                                    </Link>
                                                })}
                                            </dd>
                                        </div>
                                    }
                                })()}
                            </dl>
                        </div>
                    </div>
                    {(() => {
                      if(piece.comments) {
                          return <dl>
                              <dt>Description</dt>
                              <dd dangerouslySetInnerHTML={createMarkup(piece.comments)}></dd>
                          </dl>
                      }
                    })()}
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`    
    query($slug: String!) {
        wagtail {
            piece(slug: $slug) {
                slug
                dimensions
                title
                price
                sold
                commission
                comments
                categories {
                    slug
                    name
                }
                mediums {
                    slug
                    name
                }
                image {
                    ...thumbnail
                    full: rendition(height: 1200, width: 1200) {
                        url
                        height
                        width
                        localFile {
                            childImageSharp {
                                fixed(width: null, quality: 90) {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
